import axios from "axios";
import { getApiUrl } from "../realms";

const createNewReseller = async (realmId, reseller) => {
    try {
        const response = await axios.post(
            `${getApiUrl()}/realm/${realmId}/reseller/create`, reseller
        );
        if (response.status == 201) {
            return response.data;
        }
        throw new Error(`Create New Reseller returned an error status: ${response?.status} with data: ${JSON.stringify(response?.data)}`);
    } catch (e) {
        console.error(e)
        throw new Error(`Create New Reseller returned an error status: ${e.response?.status} with data: ${JSON.stringify(e.response?.data)}`);
    }
}

export default {
    createNewReseller
};
