import axios from "axios";
import { getApiUrl } from "../realms";

const restorePlaySub = async (realmId, businessId, purchaseToken) => {
    const response = await axios.post(
        `${getApiUrl()}/realm/${realmId}/subscription/restorePlaySubV1`,
        {
            businessId,
            purchaseToken
        }
    );
    return response.data || [];
}

const transferSubscription = async ({ realmId, subscriptionId, targetBusinessId }) => {
    await axios.post(
        `${getApiUrl()}/realm/${realmId}/subscription/${subscriptionId}/transfer`,
        {
            targetBusinessId
        }
    );
}

const cancelSubscription = async ({ realmId, subscriptionId, cancellationReason }) => {
    await axios.post(
        `${getApiUrl()}/realm/${realmId}/subscription/${subscriptionId}/cancel`,
        {
            refund: "full",
            cancellationReason
        }
    );
}

const enquireCancelSubscription = async ({ realmId, subscriptionId, cancellationReason }) => {
    const response = await axios.post(
        `${getApiUrl()}/realm/${realmId}/subscription/${subscriptionId}/enquireCancel`,
        {
            refund: "full",
            cancellationReason
        }
    );
    return response.data
}

const getStripeCustomerPage = async (realmId, subscriptionId) => {
    const response = await axios.post(
        `${getApiUrl()}/realm/${realmId}/subscription/${subscriptionId}/getStripeCustomerPage`,
        {}
    );
    return response.data
}

export {
    restorePlaySub,
    transferSubscription,
    cancelSubscription,
    enquireCancelSubscription,
    getStripeCustomerPage
}